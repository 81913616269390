import { get, set } from 'idb-keyval';
import * as environment from '../../config/environment.json';

export class UrlService {
    constructor() {
    }

    async impiloUrl() {
        if (location.hostname === 'localhost') {
            if (environment.localApi) {
                return 'http://localhost:5100/api/impilo';
            } else {
                return 'https://develop.impilo.biz/api/impilo';
            }
        } else {
            return location.origin + '/api/impilo';
        }
    }

    async identityUrl() {
        if (location.hostname === 'localhost') {
            if (environment.localApi) {
                return 'http://localhost:5100/api/identity';
            } else {
                return 'https://develop.impilo.biz/api/identity';
            }
        } else {
            return location.origin + '/api/identity';
        }
    }

    async hubUrl() {
        if (location.hostname === 'localhost') {
            if (environment.localApi) {
                return 'http://localhost:5300/api/hub';
            } else {
                return 'https://develop.impilo.biz/api/hub';
            }
        } else {
            return location.origin + '/api/hub';
        }
    }
}