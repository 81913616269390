import { Container } from 'aurelia-framework';
import { dispatchify, Reducer, rehydrateFromLocalStorage, Store } from 'aurelia-store';
import * as decode from 'jwt-decode';
import { AppService } from 'plugins/blister/app-service';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { ImpiloDb } from 'services/repositories';
import { IAuthToken, IState, IStatePager } from './state';
import store from './store';

const _resetState = (state: IState): IState => {
    const empty: IState = {
        authToken: {
            token: '',
            refreshToken: '',
            decoded: {},
            currentUser: {
                email: '',
                userName: '',
                user: '',
                pharmacy: '',
                fullName: '',
                roles: '',
            }
        },
        plugins: {
            admin: {
                fulltext: {
                    users: {
                        filter: '',
                        results: []
                    }
                }
            },
            blister: {
                currentPharmacy: {
                    displayName: '',
                    pharmacy: null
                },
                currentPatient: {
                    displayName: '',
                    patient: null
                },
                fulltext: {
                    patients: {
                        filter: '',
                        results: []
                    },
                    doctors: {
                        filter: '',
                        results: []
                    },
                    medications: {
                        filter: '',
                        results: []
                    },
                    pharmacies: {
                        filter: '',
                        results: []
                    },
                    users: {
                        filter: '',
                        results: []
                    },
                    patientMedications: {
                        filter: '',
                        results: []
                    },
                    careHomes: {
                        filter: '',
                        results: []
                    },
                    packingGroups: {
                        filter: '',
                        results: []
                    },
                    subGroups: {
                        filter: '',
                        results: []
                    },
                    userSessions: {
                        filter: '',
                        results: []
                    }
                }
            },
            careHome: {
                currentCareHome: {
                    displayName: '',
                    careHome: null
                },
                currentPatient: {
                    displayName: '',
                    patient: null
                },
                fulltext: {
                    patients: {
                        filter: '',
                        results: []
                    },
                }
            }
        }
    }
    store.resetToState(empty);
    return empty;
};
store.registerAction(_resetState.name, _resetState);

const _pharmacyChanged = (state: IState, pharmacy: any): IState => {

    Container.instance.get(AppService).clear();

    const newState = _resetState(state);

    newState.plugins.blister.currentPharmacy.displayName = pharmacy ? pharmacy.name : '';
    newState.plugins.blister.currentPharmacy.pharmacy = pharmacy;

    return newState;
};
store.registerAction(_pharmacyChanged.name, _pharmacyChanged);

const _loginAction = (state: IState, token: string, refreshToken: string) => {
    const newState = { ...state };

    const decoded: any = decode(token);

    const authToken = {
        token,
        refreshToken,
        decoded,
        currentUser: {
            email: decoded.email,
            userName: decoded.sub,
            user: decoded.user,
            pharmacy: decoded.pharmacy,
            fullName: decoded.fullname,
            roles: decoded.roles
        }
    };

    newState.authToken = authToken;

    return newState;
};
store.registerAction(_loginAction.name, _loginAction);

const _logoutAction = (state: IState) => {
    const newState = _resetState(state);
    return newState;
};
store.registerAction(_logoutAction.name, _logoutAction);

const _patientFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.Patient[], pager?: IStatePager): IState => {
    const newState = { ...state };

    newState.plugins.blister.currentPatient = {
        displayName: '',
        patient: null
    };

    newState.plugins.blister.fulltext.patients = {
        filter,
        results: results as [],
        pager
    };
    return newState;
};
store.registerAction(_patientFullTextAction.name, _patientFullTextAction);

const _patientSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.patients.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_patientSelectedRowAction.name, _patientSelectedRowAction);

const _patientChanged = (state: IState, displayName: string, patient: any): IState => {
    const newState = { ...state };
    newState.plugins.blister.currentPatient.displayName = displayName;
    newState.plugins.blister.currentPatient.patient = patient;

    return newState;
};
store.registerAction(_patientChanged.name, _patientChanged);

const _doctorFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.Doctor[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.doctors = {

        filter,
        results: results as [],
        pager
    };

    return newState;
};
store.registerAction(_doctorFullTextAction.name, _doctorFullTextAction);

const _doctorSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.doctors.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_doctorSelectedRowAction.name, _doctorSelectedRowAction);

const _medicationFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.Medication[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.medications = {
        filter,
        results: results as [],
        pager
    };
    return newState;
};
store.registerAction(_medicationFullTextAction.name, _medicationFullTextAction);

const _medicationSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.medications.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_medicationSelectedRowAction.name, _medicationSelectedRowAction);

const _pharmacyFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.Pharmacy[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.pharmacies = {
        filter,
        results: results as [],
        pager
    };
    return newState;
};
store.registerAction(_pharmacyFullTextAction.name, _pharmacyFullTextAction);

const _pharmacySelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.pharmacies.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_pharmacySelectedRowAction.name, _pharmacySelectedRowAction);


const _userFullTextAction = (state: IState, filter: string, results: any[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.users = {
        filter,
        results: results as [],
        pager
    };
    return newState;
};
store.registerAction(_userFullTextAction.name, _userFullTextAction);

const _userSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.pharmacies.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_userSelectedRowAction.name, _userSelectedRowAction);

const _patientMedicationFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.PatientMedication[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.patientMedications = {
        filter,
        results: results as [],
        pager
    };
    return newState;
};
store.registerAction(_patientMedicationFullTextAction.name, _patientMedicationFullTextAction);

const _patientMedicationSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.patientMedications.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_patientMedicationSelectedRowAction.name, _patientMedicationSelectedRowAction);

const _careHomeFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.CareHome[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.careHomes = {
        filter,
        results: results as [],
        pager
    };

    return newState;
};
store.registerAction(_careHomeFullTextAction.name, _careHomeFullTextAction);

const _careHomeSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.careHomes.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_careHomeSelectedRowAction.name, _careHomeSelectedRowAction);

const _packingGroupFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.CareHome[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.packingGroups = {
        filter,
        results: results as [],
        pager
    };

    return newState;
};
store.registerAction(_packingGroupFullTextAction.name, _packingGroupFullTextAction);

const _packingGroupSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.packingGroups.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_packingGroupSelectedRowAction.name, _packingGroupSelectedRowAction);

const _subGroupFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.CareHome[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.subGroups = {
        filter,
        results: results as [],
        pager
    };

    return newState;
};
store.registerAction(_subGroupFullTextAction.name, _subGroupFullTextAction);

const _subGroupSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.subGroups.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_subGroupSelectedRowAction.name, _subGroupSelectedRowAction);

const _careHomeChanged = (state: IState, carehome: any): IState => {

    Container.instance.get(AppService).clear();

    const newState = _resetState(state);

    newState.plugins.careHome.currentCareHome.displayName = carehome ? carehome.name : '';
    newState.plugins.careHome.currentCareHome.careHome = carehome;

    return newState;
};
store.registerAction(_careHomeChanged.name, _careHomeChanged);

const _careHomePatientChanged = (state: IState, displayName: string, patient: any): IState => {
    const newState = { ...state };
    newState.plugins.careHome.currentPatient.displayName = displayName;
    newState.plugins.careHome.currentPatient.patient = patient;

    return newState;
};
store.registerAction(_careHomePatientChanged.name, _careHomePatientChanged);

const _userSessionFullTextAction = (state: IState, filter: string, results: ImpiloDb.Tables.UserSession[], pager?: IStatePager): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.userSessions = {
        filter,
        results: results as [],
        pager
    };

    return newState;
};
store.registerAction(_userSessionFullTextAction.name, _userSessionFullTextAction);

const _userSessionSelectedRowAction = (state: IState, selectedRow?: number): IState => {
    const newState = { ...state };
    newState.plugins.blister.fulltext.userSessions.selectedRow = selectedRow;
    return newState;
};
store.registerAction(_userSessionSelectedRowAction.name, _userSessionSelectedRowAction);


export const resetState = dispatchify(_resetState);
export const pharmacyChanged = dispatchify(_pharmacyChanged);
export const loginAction = dispatchify(_loginAction);
export const logoutAction = dispatchify(_logoutAction);
export const patientFullTextAction = dispatchify(_patientFullTextAction);
export const patientSelectedRowAction = dispatchify(_patientSelectedRowAction);
export const patientChanged = dispatchify(_patientChanged);
export const doctorFullTextAction = dispatchify(_doctorFullTextAction);
export const doctorSelectedRowAction = dispatchify(_doctorSelectedRowAction);
export const medicationFullTextAction = dispatchify(_medicationFullTextAction);
export const medicationSelectedRowAction = dispatchify(_medicationSelectedRowAction);
export const pharmacyFullTextAction = dispatchify(_pharmacyFullTextAction);
export const pharmacySelectedRowAction = dispatchify(_pharmacySelectedRowAction);
export const careHomeFullTextAction = dispatchify(_careHomeFullTextAction);
export const careHomeSelectedRowAction = dispatchify(_careHomeSelectedRowAction);
export const packingGroupFullTextAction = dispatchify(_packingGroupFullTextAction);
export const packingGroupSelectedRowAction = dispatchify(_packingGroupSelectedRowAction);
export const subGroupFullTextAction = dispatchify(_subGroupFullTextAction);
export const subGroupSelectedRowAction = dispatchify(_subGroupSelectedRowAction);
export const userFullTextAction = dispatchify(_userFullTextAction);
export const userSelectedRowAction = dispatchify(_userSelectedRowAction);
export const patientMedicationFullTextAction = dispatchify(_patientMedicationFullTextAction);
export const patientMedicationSelectedRowAction = dispatchify(_patientMedicationSelectedRowAction);
export const userSessionFullTextAction = dispatchify(_userSessionFullTextAction);
export const userSessionSelectedRowAction = dispatchify(_userSessionSelectedRowAction);

export const careHomeChanged = dispatchify(_careHomeChanged);
export const careHomePatientChanged = dispatchify(_careHomePatientChanged);

export const getStateProperty = async (...properties: string[]) => {
    return new Promise(async (resolve, reject) => {
        const subscription = store.state.pipe(pluck(...properties)).subscribe(
            (value) => { resolve(value); },
            (error) => { reject(error); }
        );
        subscription.unsubscribe();
    });
};
