import { ImpiloDb } from 'plugins/data-models';

export interface IState {
    authToken: IAuthToken;
    plugins: {
        admin: {
            fulltext: {
                users: IStateFullText;
            };
        };
        blister: {
            currentPharmacy: {
                displayName: string;
                pharmacy: ImpiloDb.Tables.Pharmacy;
            };
            currentPatient: {
                displayName: string;
                patient: ImpiloDb.Tables.Patient;
            };
            fulltext: {
                patients: IStateFullText;
                doctors: IStateFullText;
                medications: IStateFullText;
                pharmacies: IStateFullText;
                users: IStateFullText;
                patientMedications: IStateFullText;
                careHomes: IStateFullText;
                packingGroups: IStateFullText;
                subGroups: IStateFullText;
                userSessions: IStateFullText;
            };
        };
        careHome: {
            currentCareHome: {
                displayName: string;
                careHome: ImpiloDb.Tables.CareHome;
            };
            currentPatient: {
                displayName: string;
                patient: ImpiloDb.Tables.Patient;
            };
            fulltext: {
                patients: IStateFullText;
            };
        }
    };
}

export interface IStateFullText {
    filter: string;
    results: [];
    selectedRow?: number;
    pager?: IStatePager;
}

export interface IStatePager {
    from: number;
    to: number;
    itemsPerPage: number;
    pageCount: number;
    pageNumber: number;
    totalItems: number;
}

export interface IAuthToken {
    token: string;
    refreshToken: string;
    decoded: {};
    currentUser: {
        email: string;
        userName: string;
        user: string;
        pharmacy?: string;
        fullName: string;
        roles: string;
    };
}

export const initialState: IState = {
    authToken: {
        token: '',
        refreshToken: '',
        decoded: {},
        currentUser: {
            email: '',
            userName: '',
            user: '',
            pharmacy: '',
            fullName: '',
            roles: '',
        }
    },
    plugins: {
        admin: {
            fulltext: {
                users: {
                    filter: '',
                    results: []
                }
            }
        },
        blister: {
            currentPharmacy: {
                displayName: '',
                pharmacy: null
            },
            currentPatient: {
                displayName: '',
                patient: null
            },
            fulltext: {
                patients: {
                    filter: '',
                    results: []
                },
                doctors: {
                    filter: '',
                    results: []
                },
                medications: {
                    filter: '',
                    results: []
                },
                pharmacies: {
                    filter: '',
                    results: []
                },
                users: {
                    filter: '',
                    results: []
                },
                patientMedications: {
                    filter: '',
                    results: []
                },
                careHomes: {
                    filter: '',
                    results: []
                },
                packingGroups: {
                    filter: '',
                    results: []
                },
                subGroups: {
                    filter: '',
                    results: []
                },
                userSessions: {
                    filter: '',
                    results: []
                }
            }
        },
        careHome :{
            currentCareHome: {
                displayName: '',
                careHome: null
            },
            currentPatient: {
                displayName: '',
                patient: null
            },
            fulltext: {
                patients: {
                    filter: '',
                    results: []
                },
            }
        }
    }
};
