import { singleton } from 'aurelia-dependency-injection';
import { autoinject } from 'aurelia-framework';

import * as toastr from 'toastr';
import 'toastr/build/toastr.min.css';

//declare var toastr: any;

@singleton()
export class ToastrService {

  private readonly instance: any;
  private readonly options: Partial<any> = {
    positionClass: 'toast-top-right',
    preventDuplicates: true
  };

  constructor() {
    if (toastr) {
      this.instance = toastr;
    }
  }

  public success(message: string, title?: string, overrides?: any) {
    let settings = this.options;
    if (overrides) {
      settings = { ...overrides };
    }
    if (this.instance) {
      return this.instance.success(message, title, settings || overrides);
    }
  }

  public error(message: string, title?: string, overrides?: any) {
    let settings = this.options;
    if (overrides) {
      settings = { ...overrides };
    }
    if (this.instance) {
      return this.instance.error(message, title, settings || overrides);
    }
  }

  public info(message: string, title?: string, overrides?: any) {
    let settings = this.options;
    if (overrides) {
      settings = { ...overrides };
    }
    if (this.instance) {
      return this.instance.info(message, title, settings || overrides);
    }
  }

  public warning(message: string, title?: string, overrides?: any) {
    let settings = this.options;
    if (overrides) {
      settings = { ...overrides };
    }
    if (this.instance) {
      return this.instance.warning(message, title, settings || overrides);
    }
  }

  public clear(toast?, clearOptions?: { force: boolean }) {
    if (this.instance) {
      this.instance.clear(toast, clearOptions);
    }
  }

  public remove() {
    if (this.instance) {
      this.instance.remove();
    }
  }

  public subscribe(callback: (response: any) => any): void {
    if (this.instance) {
      this.instance.subscribe(callback);
    }
  }
}
