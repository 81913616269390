import { autoinject } from 'aurelia-framework';
import { ImpiloRepository } from './repositories';
import * as signalR from "@microsoft/signalr";
import { json } from 'aurelia-fetch-client';
import { title } from 'process';
import { AuthService } from './auth-service';

@autoinject()
export class PrintService {

    week1Start: Date
    printWeek1 = true;
    printWeek2 = false;
    printWeek3 = false;
    printWeek4 = false;
    patientIds = [];
    updateLastPrinted = true;

    selectedChoice = 0;
    printDialogTitle = '';

    printPatientsConsolidate = false;

    guid = "";
    message = "";

    patientCounter = 0.0;
    patientTotal = 0.0;
    patientPercentage = 0;
    patientProgress = "";

    dateOverlayCounter = 0.0;
    dateOverlayTotal = 0.0;
    dateOverlayPercentage = 0;
    dateOverlayProgess = '';

    uploadingPercentage = 0.0;

    fetching = false;

    connection: signalR.HubConnection;
    finished: () => void;

    constructor(private readonly repository: ImpiloRepository, private readonly authService: AuthService) {
        this.reset();
        this.initSignalR();
    }

    private async queueTreatmentPlan(patientIds: number[], includeImages: number, startWeek: Date, weekFlag1: boolean, weekFlag2: boolean, weekFlag3: boolean, weekFlag4: boolean, updateLastPrinted: boolean) {
        const pharmacyGuid = this.repository.pharmacyGuid();
        const delimited = patientIds.join(",");
        const userGuid = this.authService.currentUser().user;

        const body = {
            pharmacyGuid,
            patientIds: delimited,
            includeImages,
            startWeek,
            weekFlag1,
            weekFlag2,
            weekFlag3,
            weekFlag4,
            updateLastPrinted,
            userGuid
        };

        const awaited = await this.repository.http.fetch("/ReportQueueTreatmentPlan", {
            body: json(body),
            method: "POST",
        });
        const result = await awaited.json();

        return result;
    }

    private async queueLabel(patientIds: number[], includeImages: number, startWeek: Date, weekFlag1: boolean, weekFlag2: boolean, weekFlag3: boolean, weekFlag4: boolean) {
        const pharmacyGuid = this.repository.pharmacyGuid();
        const delimited = patientIds.join(",");
        const userGuid = this.authService.currentUser().user;

        const body = {
            pharmacyGuid,
            patientIds: delimited,
            includeImages,
            startWeek,
            weekFlag1,
            weekFlag2,
            weekFlag3,
            weekFlag4,
            userGuid
        };

        const awaited = await this.repository.http.fetch("/ReportQueueLabel", {
            body: json(body),
            method: "POST",
        });
        const result = await awaited.json();

        return result;
    }

    private async queuePatientList(patientIds: number[], includeImages: number, startWeek: Date, consolidate: boolean, weekFlag1: boolean, weekFlag2: boolean, weekFlag3: boolean, weekFlag4: boolean) {
        const pharmacyGuid = this.repository.pharmacyGuid();
        const delimited = patientIds.join(",");
        const userGuid = this.authService.currentUser().user;

        const body = {
            pharmacyGuid,
            patientIds: delimited,
            includeImages,
            startWeek,
            consolidate,
            weekFlag1,
            weekFlag2,
            weekFlag3,
            weekFlag4,
            userGuid
        };

        const awaited = await this.repository.http.fetch("/ReportQueuePatientList", {
            body: json(body),
            method: "POST",
        });
        const result = await awaited.json();

        return result;
    }

    private async queueResidentPackingList(patientIds: number[], includeImages: number, startWeek: Date, consolidate: boolean, weekFlag1: boolean, weekFlag2: boolean, weekFlag3: boolean, weekFlag4: boolean) {
        const pharmacyGuid = this.repository.pharmacyGuid();
        const delimited = patientIds.join(",");
        const userGuid = this.authService.currentUser().user;

        const body = {
            pharmacyGuid,
            patientIds: delimited,
            includeImages,
            startWeek,
            consolidate,
            weekFlag1,
            weekFlag2,
            weekFlag3,
            weekFlag4,
            userGuid
        };

        const awaited = await this.repository.http.fetch("/ReportQueueResidentPackingList", {
            body: json(body),
            method: "POST",
        });
        const result = await awaited.json();

        return result;
    }

    reset() {
        this.week1Start = this.getNextMon(new Date());
        this.printWeek1 = true;
        this.printWeek2 = false;
        this.printWeek3 = false;
        this.printWeek4 = false;
    }

    getNextMon(date: Date) {
        let loop = new Date(date);
        do {
            const newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
        } while (loop.getDay() != 1);

        return loop;
    }

    async downloadPrintWeekLog(guid: string, filename: string) {
        var uri = await this.repository.getBlobSasUri(guid, filename);
        setTimeout(() => {
            const link = document.createElement("a");
            link.href = uri;
            link.download = "uri";
            link.click();
        }, 50);
    }

    async initSignalR() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${this.repository.http.baseUrl}/progress`, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .configureLogging(signalR.LogLevel.Trace)
            .build();

        this.connection.serverTimeoutInMilliseconds = 1000 * 60 * 60 * 24;

        this.connection.on("progress", (counter, total, patient) => {
            this.patientCounter = +counter;
            this.patientTotal = +total;
            this.patientPercentage = (this.patientCounter / this.patientTotal) * 100;
            this.patientProgress = `${counter} of ${total}`;
        });

        this.connection.on("cancelled", () => {
            this.message = "cancelled";
        });

        this.connection.on("message", (message) => {
            this.message = message;
        });

        this.connection.on("uploading", (percentage) => {
            this.uploadingPercentage = +percentage;
        });

        this.connection.on("progressDateOverlay", (counter, total) => {
            this.dateOverlayCounter = +counter;
            this.dateOverlayTotal = +total;
            this.dateOverlayPercentage = (this.dateOverlayCounter / this.dateOverlayTotal) * 100;
            this.dateOverlayProgess = `${counter} of ${total}`;
        });

        this.connection.on("finished", (uri) => {
            this.message = "finished";

            setTimeout(() => {
                const link = document.createElement("a");
                link.href = uri;
                link.download = "uri";
                link.click();
            }, 50);

            if (this.finished) {
                this.finished();
            }

            // this.connection.stop();
        });

        console.log(this.connection);
        await this.connection.start();
    }

    async cancel(): Promise<void> {
        if (this.connection.state === "Connected") {
            if (this.guid.length > 0) {
                await this.connection.invoke("CancelJob", this.guid);
            }
            // await this.connection.stop();
        }
    }

    async print(finished: () => void) {
        this.message = "";

        this.patientProgress = "";
        this.patientCounter = 0.0;
        this.patientTotal = 0.0;
        this.patientPercentage = 0;

        this.dateOverlayProgess = "";
        this.dateOverlayCounter = 0.0;
        this.dateOverlayTotal = 0.0;
        this.dateOverlayPercentage = 0;

        this.uploadingPercentage = 0;

        if (!this.fetching) {
            this.finished = finished;
            this.fetching = true;

            // cancel previous jobs
            await this.cancel();

            let awaitedQueue;

            switch (this.selectedChoice) {
                case 0:
                    this.printDialogTitle = 'Treatment Plan Progress';
                    awaitedQueue = await this.queueTreatmentPlan(this.patientIds, 1, this.week1Start, this.printWeek1, this.printWeek2, this.printWeek3, this.printWeek4, this.updateLastPrinted);
                    break;

                case 1:
                    this.printDialogTitle = 'Label Progress';
                    awaitedQueue = await this.queueLabel(this.patientIds, 1, this.week1Start, this.printWeek1, this.printWeek2, this.printWeek3, this.printWeek4);
                    break;

                case 2:
                    this.printDialogTitle = 'Patient List Progress';
                    awaitedQueue = await this.queuePatientList(this.patientIds, 1, this.week1Start, this.printPatientsConsolidate, this.printWeek1, this.printWeek2, this.printWeek3, this.printWeek4);
                    break;

                case 3:
                    this.printDialogTitle = 'Resident Packing List Progress';
                    awaitedQueue = await this.queueResidentPackingList(this.patientIds, 1, this.week1Start, this.printPatientsConsolidate, this.printWeek1, this.printWeek2, this.printWeek3, this.printWeek4);
                    break;


            }

            // current job
            this.guid = awaitedQueue.results[0].guid;
            this.message = "Please wait...";

            if (this.connection.state !== "Connected") {
                await this.connection.start();
            }
            const guid = await this.connection.invoke("AddJob", this.guid);
            if (this.guid != guid) {
                alert('AddJob failed');
            }
        }
        this.fetching = false;
    }
}

function formattedDate(datetime) {
    function appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n
    }

    let formatted_date = datetime.getFullYear() + "-" + appendLeadingZeroes(datetime.getMonth() + 1) + "-" + appendLeadingZeroes(datetime.getDate()) + " " + appendLeadingZeroes(datetime.getHours()) + ":" + appendLeadingZeroes(datetime.getMinutes()) + ":" + appendLeadingZeroes(datetime.getSeconds())

    return formatted_date;
}