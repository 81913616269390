import { urlUtils } from '@dts/scriptlib';
import { HttpClient, json } from 'aurelia-fetch-client';
import { IApiResult, ImpiloDb } from 'plugins/data-models';

export class IdentityRepository extends ImpiloDb.ImpiloDbRepository {
    constructor(http: HttpClient) {
        super(http);
    }

    getPharmacy(guid: string = null, userGuid: string = null): Promise<IApiResult> {
        return this.procedures.spgPharmacy(guid, userGuid);
    }

    filterAspNetUser(filter: string, page: number = 1, size: number = 10): Promise<IApiResult> {
        const pharmacyGuid: string = localStorage.getItem('pharmacy');
        return this.procedures.spfAspNetUser(filter, pharmacyGuid, page, size);
    }

    filterAspNetUserByPharmacy(filter: string, pharmacyGuid?: string, page: number = 1, size: number = 10): Promise<IApiResult> {
        return this.procedures.spfAspNetUser(filter, pharmacyGuid, page, size);
    }

    getAspNetUser(guid: string): Promise<IApiResult> {
        return this.procedures.spgAspNetUser(guid);
    }

    public register(parameters: RegisterModel): Promise<IApiResult> {
        return this.methods.post(`/Register`, parameters);
    }

    public addUser(parameters: AddUserModel): Promise<IApiResult> {
        return this.methods.post(`/AddUser`, parameters);
    }

    public updateUser(parameters: UpdateUserModel): Promise<IApiResult> {
        return this.methods.post(`/UpdateUser`, parameters);
    }
    public login(parameters: LoginModel): Promise<IApiResult> {
        return this.methods.post(`/Login`, parameters);
    }

    public refreshToken(parameters: RefreshTokenModel): Promise<IApiResult> {
        return this.methods.post(`/RefreshToken`, parameters);
    }

    public sendConfirmEmail(email: string, ignoreConfirmed: boolean = false): Promise<IApiResult> {
        const returnUrl = encodeURIComponent(`${urlUtils.getAbsoluteDomainUrl()}/#/account/email-confirmed`);
        return this.methods.get(`/SendConfirmEmail?returnUrl=${returnUrl}&email=${email}&ignoreConfirmed=${ignoreConfirmed}`);
    }

    public confirmEmail(email: string, token: string): Promise<IApiResult> {
        email = encodeURIComponent(email);
        token = encodeURIComponent(token);
        return this.methods.get(`/ConfirmEmail?email=${email}&token=${token}`);
    }

    public sendPasswordResetToken(email: string): Promise<IApiResult> {
        const returnUrl = encodeURIComponent(`${urlUtils.getAbsoluteDomainUrl()}/#/account/reset-password`);
        return this.methods.get(`/SendPasswordResetToken?returnUrl=${returnUrl}&email=${email}`);
    }

    public resetPassword(userName: string, token: string, password: string) {
        return this.methods.get(`/ResetPassword?userName=${userName}&token=${token}&password=${password}`);
    }

    public changePassword(userName: string, currentPassword: string, password: string) {
        return this.methods.get(`/ChangePassword?userName=${userName}&currentPassword=${currentPassword}&password=${password}`);
    }

    public updatePassword(userName: string, newPassword: string): Promise<IApiResult> {
        return this.methods.get(`/UpdatePassword?userName=${userName}&newPassword=${newPassword}`);
    }
}

export interface RegisterModel {
    userName: string;
    password: string;
    email: string;
    pharmacyName: string;
    bHFNumber: string;
    vATRegistrationNumber: string;
    contactPerson: string;
    telephoneNumber: string;
    faxNumber: string;
    cellPhoneNumber: string;
    emailAddress: string;
    addressLine1: string;
    addressLine2: string;
    suburb: string;
    city: string;
    postalCode: string;
}

export interface AddUserModel {
    userName: string;
    password: string;
    email: string;
    emailConfirmed: boolean;
    fullname: string;
    phoneNumber: string;
    isActive: boolean;
    role: string;
    pharmacyGuid: string;
}

export interface UpdateUserModel {
    id: string;
    fullname: string;
    phoneNumber: string;
    emailConfirmed: boolean;
    isActive: boolean;
    role: string;
    pharmacyGuid: string;
}

export interface LoginModel {
    userName: string;
    password: string;
    ipAddress: string;
}

export interface RefreshTokenModel {
    token: string;
    refreshToken: string;
}
