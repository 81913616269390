import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { connectTo, Store } from 'aurelia-store';
import * as decode from 'jwt-decode';
import { ImpiloDb } from 'plugins/data-models';
import { pluck } from 'rxjs/operators';
import { IdentityRepository, LoginModel, RefreshTokenModel } from './identity-repository';
import { getStateProperty, loginAction, logoutAction, pharmacyChanged } from './state/actions';
import { IAuthToken, IState } from './state/state';
import { isEmptyGuid } from '@dts/scriptlib';
import { del } from 'idb-keyval';

// @connectTo<IState>({
//     selector: {
//         authToken: (store) => store.state.pipe(pluck('authToken')),
//         currentPharmacy: (store) => store.state.pipe(pluck('plugins', 'blister', 'currentPharmacy')),
//     }
// })
@autoinject()
export class AuthService {

    private interval;
    private authToken: IAuthToken;
    // currentPharmacy;

    constructor(private readonly repository: IdentityRepository, private readonly router: Router, private readonly store: Store<IState>) {
        this.authToken = this.getToken();
        this.startIntervalCheck();
    }

    pharmacy: ImpiloDb.Tables.Pharmacy ;
    async getCurrentUserPharmacy() {
        const spgPharmacy = await this.repository.getPharmacy(this.authToken.currentUser.pharmacy);
        if (spgPharmacy.results.length > 0) {
            this.pharmacy = spgPharmacy.results[0];
            return this.pharmacy
        }
    }

    async getCurrentPharmacy() {
        const spgPharmacy = await this.repository.getPharmacy(this.currentPharmacyGuid());
        if (spgPharmacy.results.length > 0) {
            this.pharmacy = spgPharmacy.results[0];
            return this.pharmacy
        }
    }

    isPharmacyActive(): boolean {
        return  this.isGlobalAdmin() || this.pharmacy?.isActive;
    }

    public startIntervalCheck() {
        clearInterval(this.interval);
        this.interval = setInterval(this.intervalCheck.bind(this), 5000);
    }

    async intervalCheck() {
        clearInterval(this.interval);
        if (this.authToken?.decoded) {
            const expiresIn = tokenExpiresIn(this.authToken.decoded);
            // console.log(expiresIn);
            if (expiresIn <= 10 && this.authToken.refreshToken.length > 0) {
                await this.refresh(this.authToken.token, this.authToken.refreshToken);
            }
            this.startIntervalCheck();
        }
    }

    public isAuthenticated() {
        this.authToken = JSON.parse(localStorage.getItem('authToken'));
        if (this.authToken && this.authToken.token.length == 0) {
            this.authToken = JSON.parse(localStorage.getItem('authToken'));
        }

        if (this.authToken && this.authToken.token.length == 0) {
            return false;
        }

        if (this.authToken && this.authToken.token.length > 0) {
            return isTokenValid(this.authToken.decoded);
        }
        return undefined;
    }

    public isAuthorized(role) {
        if (this.authToken) {
            return isTokenValid(this.authToken.decoded);
        }
        return false;
    }

    public currentUser(): { email: string; userName: string; user: string; pharmacy?: string; } {
        if (this.authToken) {
            return this.authToken.currentUser;
        }
        return null;
    }

    public get getSession() {
        return localStorage.getItem('session')
    }

    public isGlobalAdmin(): boolean {
        if (this.authToken) {
            const roles = this.authToken.currentUser.roles.split(';');
            const index = roles.findIndex(w => w === 'GlobalAdmin') > -1;
            return index;
        }
        return false;
    }

    public currentPharmacyGuid(): string {
        return localStorage.getItem('pharmacy');
    }

    public async login(userName: string, password: string) {

        const parameters: LoginModel = {
            userName: userName,
            password: password,
            ipAddress: ''
        };

        this.clearToken();

        const result = await this.repository.login(parameters);
        if (result.ok) {
            this.authToken = await this.setToken(result.results[0].accessToken, result.results[0].refreshToken);
            const session = result.results[0].session;
            localStorage.setItem('session', session);

            if (!isEmptyGuid(this.authToken.currentUser.pharmacy)) {
                const spgPharmacy = await this.repository.getPharmacy(this.authToken.currentUser.pharmacy);
                if (spgPharmacy.results.length > 0) {
                    this.pharmacy = spgPharmacy.results[0];
                    await pharmacyChanged(spgPharmacy.results[0]);
                    localStorage.setItem('pharmacy', spgPharmacy.results[0].guid);  // HACK
                } else {
                    localStorage.removeItem('pharmacy');
                }
            }
        }
        return result;
    }

    clearToken(email?, userName?): IAuthToken {
        const authToken = {
            token: '',
            refreshToken: '',
            decoded: {},
            currentUser: {
                email,
                userName,
                user: '',
                pharmacy: '',
                fullName: '',
                roles: '',
            }
        };

        localStorage.setItem('authToken', JSON.stringify(authToken));
        localStorage.removeItem('pharmacy');
        return this.getToken();
    }

    async setToken(token, refreshToken): Promise<IAuthToken> {

        const decoded: any = decode(token);

        const authToken = {
            token,
            refreshToken,
            decoded,
            currentUser: {
                email: decoded.email,
                userName: decoded.sub,
                user: decoded.user,
                pharmacy: decoded.pharmacy,
                fullName: decoded.fullname,
                roles: decoded.roles,
            }
        };

        localStorage.setItem('authToken', JSON.stringify(authToken));

        await loginAction(token, refreshToken);

        this.startIntervalCheck();

        return authToken;
    }

    getToken(): IAuthToken {
        return JSON.parse(localStorage.getItem('authToken'));
    }

    public async refresh(token: string, refreshToken: string) {

        const parameters: RefreshTokenModel = {
            token,
            refreshToken
        };

        const result = await this.repository.refreshToken(parameters);
        if (result.ok) {
            this.authToken = await this.setToken(result.results[0].accessToken, result.results[0].refreshToken);
        } else {
            await this.logout();
        }

        return result;
    }

    async logout() {
        await del('notices');

        clearInterval(this.interval);

        this.authToken = this.clearToken(this.authToken?.currentUser.email, this.authToken?.currentUser.userName);
        await logoutAction();
        this.router.navigateToRoute('login', { username: this.authToken?.currentUser.userName });
    }

    public changePassword(userName: string, currentPassword: string, password: string) {
        return this.repository.changePassword(userName, currentPassword, password);
    }
}

export function isTokenValid(token: any) {

    if (token.nbf === undefined) {
        return undefined;
    }

    const timeNow = getTimeNowUtc();

    // console.log('isTokenValid', token.nbf, timeNow, token.exp);
    return (timeNow <= token.exp);
    // return (token.nbf <= timeNow && timeNow <= token.exp);
}

export function tokenExpiresIn(token: any) {
    let expiresIn = 0;

    if (token && token.exp) {
        const timeNow = getTimeNowUtc();
        expiresIn = token.exp - timeNow;
    }
    return expiresIn;
}

export function getTimeNowUtc() {
    return Math.round(Date.now() / 1000);
}
