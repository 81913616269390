import * as signalR from "@microsoft/signalr";
import { autoinject } from "aurelia-framework";
import { UrlService } from "services/url-service";

let hubConnectionId = null;
export { hubConnectionId };

@autoinject()
export class HubService {
  private _hubConnection: signalR.HubConnection;
  private tries = 0;

  constructor(private readonly urlService: UrlService) {}

  ensure() {
    if (this.tries++ > 2) {
      return;
    }
    return this.hubConnection();
  }

  async hubConnection(): Promise<signalR.HubConnection> {
    if (!this._hubConnection) {
      const hubUrl = await this.urlService.hubUrl();

      const url = `${hubUrl}/webclient`;
      this._hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
        })
        .configureLogging(signalR.LogLevel.Error)
        .build();

      this.addHandlers();
    }

    if (!this._hubConnection) {
      throw new Error("Hub connection not initialized");
    }

    if (this._hubConnection.state === signalR.HubConnectionState.Disconnected) {
      await this._hubConnection.start();
    }

    if (this._hubConnection.state === signalR.HubConnectionState.Connected) {
      return this._hubConnection;
    }
  }

  get connectionId() {
    return hubConnectionId;
  }

  async echo(message: string) {
    (await this.hubConnection()).send("echo", message);
  }

  addHandlers() {
    this._hubConnection.on("connected", (connectionId: string) => {
      console.log("connected to hub", connectionId);
      hubConnectionId = connectionId;

      const session = localStorage.getItem("session");
      if (session) {
        this._hubConnection.send("connect", hubConnectionId, session);
      }
    });

    this._hubConnection.on("echo", (message: string) => {
      console.log("echo", message);
    });

    this._hubConnection.onclose(async (error) => {
      console.log("onclose", error);
    });
  }
}
