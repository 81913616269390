import { HttpClient } from 'aurelia-fetch-client';
import { Container } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { deflate } from 'pako';
import { IApiResult, IEntity, ImpiloDb } from 'plugins/data-models';
import { isEmptyGuid, json } from '@dts/scriptlib';

export class ImpiloRepository extends ImpiloDb.ImpiloDbRepository {

    constructor(http: HttpClient) {
        super(http);
    }

    pharmacyGuid(): string {
        const guid = localStorage.getItem('pharmacy');
        if (isEmptyGuid(guid)) {
            const router = Container.instance.get(Router);
            router.navigateToRoute('choosePharmacy');
        } else {
            return guid;
        }
    }

    lookups(): ImpiloRepositoryLookups {
        return new ImpiloRepositoryLookups(this);
    }

    setMapping(procedure: ImpiloDb.ProcedureEnum, map: (src) => IEntity) {
        this.procedures.setMapping(procedure, map);
    }

    async getPatient(guid: string, includeImages: boolean): Promise<ImpiloDb.Tables.Patient> {
        this.procedures.setMapping(ImpiloDb.ProcedureEnum.spgPatient, ImpiloDb.Tables.Patient.map);
        const awaited = await this.procedures.spgPatient(guid, includeImages);
        if (awaited.ok && awaited.results.length > 0) {
            return awaited.results[0];
        }
    }

    getPharmacy(guid: string = null, userGuid: string = null): Promise<IApiResult> {
        return this.procedures.spgPharmacy(guid, userGuid);
    }

    getPharmacies(): Promise<IApiResult> {
        return this.procedures.spgPharmacy(null, null);
    }

    getLookups() {
        return this.procedures.spgLookup(null);
    }

    getLookup(entity: string) {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spgLookup(entity, pharmacyGuid);
        }
    }

    getTreatmentPlan(patientIds: number[], includeImages: number): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        const delimited = patientIds.join(',');
        return this.adhoc('sprTreatmentPlans', {
            pharmacyGuid,
            patientIds: delimited,
            includeImages,
            weekFlag1: true,
            weekFlag2: true,
            weekFlag3: true,
            weekFlag4: true,
            isPreview: false
        })
    }

    getDrugInteraction(patientGuid: string): Promise<IApiResult> {
        return this.adhoc('druginteraction', {
            patientGuid
        })
    }

    async downloadDrugInteraction(patientGuid: string, filename: string) {

        const body = {
            patientGuid,
            download: true
        };

        const awaited = await this.http.fetch('/adhoc/druginteraction', {
            body: json(body),
            method: 'POST'
        });

        const blob = await awaited.blob();

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);

        return blob;
    }

    async downloadPlaceOrder(patientGuid: string, medicationWithQuantity: string, notes: string, userName: string, filename: string) {

        const body = {
            patientGuid,
            medicationWithQuantity,
            notes,
            userName,
            download: true
        };

        const awaited = await this.http.fetch('/adhoc/placeorder', {
            body: json(body),
            method: 'POST'
        });

        const blob = await awaited.blob();

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);

        return blob;
    }

    async emailPlaceOrder(patientGuid: string, medicationWithQuantity: string, notes: string, userName: string, emailAddress: string, carbonCopyPharmacy: boolean) {

        const body = {
            patientGuid,
            medicationWithQuantity,
            notes,
            userName,
            download: false,
            email: true,
            emailAddress,
            carbonCopyPharmacy
        };

        return this.adhoc('placeorder', body);
    }

    async downloadGeneratedScript(patientGuid: string, medicationWithQuantity: string, notes: string, userName: string, filename: string) {

        const body = {
            patientGuid,
            medicationWithQuantity,
            notes,
            userName,
            download: true
        };

        const awaited = await this.http.fetch('/adhoc/generatescript', {
            body: json(body),
            method: 'POST'
        });

        const blob = await awaited.blob();

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);

        return blob;
    }

    async downloadMedicationScheduled() {

        const pharmacyGuid = this.pharmacyGuid();
        await this.csv('medicationscheduled', 'sprMedicationScheduled', { pharmacyGuid });

    }

    async downloadMedicationUsage() {

        const pharmacyGuid = this.pharmacyGuid();
        await this.csv('medicationusage', 'sprMedicationUsage', { pharmacyGuid });

    }

    async DownloadToBePackedForWeek(date: Date, filename: string) {
        const pharmacyGuid = this.pharmacyGuid();
        const body = {
            pharmacyGuid,
            date,
            download: true
        };

        const awaited = await this.http.fetch('/adhoc/tobepackedforweek', {
            body: json(body),
            method: 'POST'
        });

        const blob = await awaited.blob();

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);

        return blob;
    }

    async getBlobSasUri(guid: string, filename: string) {
        const response = await this.http.get(`/GetBlobSasUri?guid=${guid}&filename=${filename}`);
        const uri = await response.json();

        return uri;
    }

    async downloadTreatmentPlan(patientIds: number[], includeImages: number, startWeek: Date, weekFlag1: boolean, weekFlag2: boolean, weekFlag3: boolean, weekFlag4: boolean) {
        const pharmacyGuid = this.pharmacyGuid();
        const delimited = patientIds.join(',');

        const body = {
            pharmacyGuid,
            patientIds: delimited,
            includeImages,
            startWeek,
            weekFlag1,
            weekFlag2,
            weekFlag3,
            weekFlag4
        };

        const awaited = await this.http.fetch('/DownloadTreatmentPlan', {
            body: json(body),
            method: 'POST'
        });
        const blob = await awaited.blob();

        return blob;
    }

    getMedicationSchedulePivot(patientMedicationGuid: string): Promise<IApiResult> {
        return this.procedures.spgMedicationSchedulePivot(patientMedicationGuid);
    }

    getPatientMedication(guid?: string, patientGuid?: string): Promise<IApiResult> {
        this.procedures.setMapping(ImpiloDb.ProcedureEnum.spgPatientMedication, ImpiloDb.Tables.PatientMedication.map);
        return this.procedures.spgPatientMedication(guid, patientGuid);
    }

    getCareHome(guid: string): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spgCareHome(guid, pharmacyGuid);
        }
    }

    getCareHomes(): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spgCareHome(null, pharmacyGuid);
        }
    }

    getCareHomesForUser(userGuid: string) {
        return this.procedures.spgCareHome(null, null, userGuid);
    }

    getSubGroup(guid: string): Promise<IApiResult> {
        return this.procedures.spgSubGroup(guid, null);
    }

    getSubGroups(careHomeGuid): Promise<IApiResult> {
        return this.procedures.spgSubGroup(null, careHomeGuid);
    }

    getPackingGroups(): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spgPackingGroup(null, pharmacyGuid);
        }
    }

    getPreloadPharmacy(entity: string, patientGuid: string = null): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spgPreload(entity, pharmacyGuid, patientGuid);
        }
    }

    getPreloadPharmacyPatient(entity: string, patientGuid: string): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spgPreload(entity, pharmacyGuid, patientGuid);
        }
    }

    getHistory(entity: string, patientGuid: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        return this.procedures.spgHistory(entity, patientGuid, page, size, total);
    }

    getPatientHistoryForJsonDiff(patientGuid: string, asOf: Date): Promise<IApiResult> {
        return this.adhoc('patienthistoryforjsondiff', {
            patientGuid,
            asOf
        })
    }

    getPatientMedicationHistoryForJsonDiff(patientMedicationGuid: string, asOf: Date): Promise<IApiResult> {
        return this.adhoc('patientmedicationhistoryforjsondiff', {
            patientMedicationGuid,
            asOf
        })
    }

    getPatientMeasurement(guid: string = null, patientGuid: string = null): Promise<IApiResult> {
        return this.procedures.spgPatientMeasurement(guid, patientGuid);
    }

    filterPatient(filter: string, isActive: boolean = true, careHomeGuid: string = null, subGroupGuid: string = null, packingGroupGuid: string = null, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spfPatient(filter, pharmacyGuid, careHomeGuid, subGroupGuid, packingGroupGuid, isActive, page, size, total);
        }
    }

    filterPharmacy(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        return this.procedures.spfPharmacy(filter, page, size, total);
    }

    filterMedication(filter: string, activeOnly: boolean = true, missingImage: boolean = false, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        return this.procedures.spfMedication(filter, activeOnly, missingImage, page, size, total);
    }

    filterCareHome(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spfCareHome(filter, pharmacyGuid, page, size, total);
        }
    }

    filterSubGroup(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spfSubGroup(filter, pharmacyGuid, page, size, total);
        }
    }

    filterPackingGroup(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spfPackingGroup(filter, pharmacyGuid, page, size, total);
        }
    }

    filterDoctor(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spfDoctor(filter, pharmacyGuid, page, size, total);
        }
    }

    filterICD10(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        return this.procedures.spfICD10(filter, page, size, total);
    }

    filterUserSession(filter: string, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        return this.procedures.spfUserSession(filter, page, size, total);
    }

    getTreatmentPlanLog(printType: string = null, page: number = 1, size: number = 10, total: number = 0): Promise<IApiResult> {
        const pharmacyGuid = this.pharmacyGuid();
        if (!isEmptyGuid(pharmacyGuid)) {
            return this.procedures.spgPrintWeekLog(pharmacyGuid, printType, page, size, total);
        }
    }

    async mergeMedicationSchedule(patientMedicationGuid: string, weekdays: string, one: number, two: number, three: number, four: number, five: number, userGuid: string): Promise<ImpiloDb.Tables.PatientMedication> {
        this.procedures.setMapping(ImpiloDb.ProcedureEnum.spmMedicationSchedule, ImpiloDb.Tables.PatientMedication.map);
        const awaited = await this.procedures.spmMedicationSchedule(patientMedicationGuid, weekdays, one, two, three, four, five, userGuid);
        if (awaited.ok && awaited.results.length > 0) {
            return awaited.results[0];
        }
    }

    mergeMedicationScheduleComplex(patientMedicationGuid: string, week: number, day: number, one: number, two: number, three: number, four: number, five: number, userGuid: string): Promise<IApiResult> {
        return this.procedures.spmMedicationScheduleComplex(patientMedicationGuid, week, day, one, two, three, four, five, userGuid);
    }

    mergePatientPhoto(guid: string, photo: any = null, photoName: string = null): Promise<IApiResult> {
        return this.procedures.spmPatientPhoto(guid, photo, photoName);
    }

    isMedicationCodeUnique(code: string) {
        return this.procedures.spgMedicationCodeIsUnique(code);
    }

    async isPatientMedicationAllowed(patientGuid: string, medicationGuid: string): Promise<{ msg: string, allow: boolean }> {
        const awaited = await this.procedures.spgPatientMedicationAllowed(patientGuid, medicationGuid);
        if (awaited.ok && awaited.results.length > 0) {
            const allow = awaited.results[0].allow;
            return { msg: awaited.results[0].msg, allow: awaited.results[0].allow == 1 }
        };
    }

    async cloneMedicationSchedule(patientMedicationGuid: string, medicationGuid: string, substitute: boolean, cloneSchedule: boolean, icd10Guid: string = null, statusGuid: string = null, instructions: string = null, userGuid: string = null): Promise<ImpiloDb.Tables.PatientMedication> {
        this.procedures.setMapping(ImpiloDb.ProcedureEnum.spmMedicationSchedule, ImpiloDb.Tables.PatientMedication.map);
        const awaited = await this.procedures.spmPatientMedicationClone(patientMedicationGuid, medicationGuid, substitute, cloneSchedule,  icd10Guid, statusGuid, instructions, userGuid);
        if (awaited.ok && awaited.results.length > 0) {
            return awaited.results[0];
        }
    }

    async htmlToPdfQueue(html): Promise<IApiResult> {

        const deflated = deflate(html);

        const formData = new FormData();

        const file = new Blob([deflated], { type: 'application/zlib' });
        formData.append('html', file);

        formData.append('compressed', 'true');
        formData.append('pharmacyGuid', this.pharmacyGuid());

        const awaited = await this.http.fetch('/HtmlToPdfQueue', {
            body: formData,
            method: 'POST'
        });

        return awaited.json();
    }

    async updateNappi() {

        const body = {
        };

        const awaited = await this.http.fetch('/adhoc/nappiupdate', {
            body: json(body),
            method: 'POST'
        });

        const blob = await awaited.blob();

        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'nappiupdate.csv';
        a.click();
        window.URL.revokeObjectURL(url);

        return blob;
    }

    async updateAccountType(accountType: number, billedAnnually: number) {
        const body = {
            pharmacyGuid: this.pharmacyGuid(),
            accountType,
            billedAnnually,
        };

        const awaited = await this.http.fetch('/adhoc/changeaccounttype', {
            body: json(body),
            method: 'POST'
        });
    }

    async isPharmacyAllowedTo(action: string) : Promise<{ isAllowed: boolean, problem: string, solution: string }>{
        const awaited = await this.http.fetch(`/IsPharmacyAllowedTo?guid=${this.pharmacyGuid()}&action=${action} `, {
            method: 'GET'
        });

        const json = await awaited.json() as IApiResult;

        return json.results[0] as { isAllowed: boolean, problem: string, solution: string };

    }

    getNotices(): Promise<IApiResult> {
        return this.procedures.spgNotice();
    }
}

// tslint:disable-next-line: max-classes-per-file
class ImpiloRepositoryLookups {
    private static lookupCache: any;

    constructor(private readonly repository: ImpiloRepository) {
    }

    async refreshLookups() {
        const awaited = await this.repository.getLookups();
        ImpiloRepositoryLookups.lookupCache = { ...awaited.results };
    }

    async titles(): Promise<ImpiloDb.Tables.Title[]> {

        const results = (await this.lookups()).title;

        return (results as []).map((entity) => {
            return ImpiloDb.Tables.Title.map(entity) as ImpiloDb.Tables.Title;
        });
    }

    async genders(): Promise<ImpiloDb.Tables.Gender[]> {

        const results = (await this.lookups()).gender;

        return (results as []).map((entity) => {
            return ImpiloDb.Tables.Gender.map(entity) as ImpiloDb.Tables.Gender;
        });
    }

    async roles(): Promise<any[]> {

        const results = (await this.lookups()).role;

        return (results as []).map((entity) => {
            return entity;
        });
    }

    async statuses(): Promise<any[]> {

        const results = (await this.lookups()).patientMedicationStatus;

        return (results as []).map((entity) => {
            return entity;
        });
    }

    async careHomes() {

        const awaited = await this.repository.getLookup('CareHome');

        return (awaited.results as []).map((entity) => {
            return entity;
        });
    }

    async packingGroups() {

        const awaited = await this.repository.getLookup('PackingGroup');

        return (awaited.results as []).map((entity) => {
            return entity;
        });
    }

    private async lookups() {
        if (!ImpiloRepositoryLookups.lookupCache) {
            await this.refreshLookups();
        }

        return ImpiloRepositoryLookups.lookupCache;
    }
}

export { ImpiloDb } from 'plugins/data-models';
